const api = {
  localization: "localization/",
  currencies: "/common/currencies",
  banks: "/common/banks",
  bankList: "common/banks",
  userdepostbanklist: "/common/bank-list",
  getGatewayList: "/mi/getGatewayList",
  countries: "/common/countries",
  cities: "/common/cities/",
  towns: "/common/towns/",
  taxbranches: "/common/taxbranches/",
  userlevels: "common/contents/userlevels",
  login: "/auth/login",
  loginOtp: "/auth/login/2fa",
  register: "/auth/register",
  logout: "/auth/logout",
  emailverify: "/auth/verification/email",
  passwordRecovery: "/auth/password",
  passwordReset: "/auth/password/reset",
  passwordResetSms: "/auth/password/reset/sms",
  sendCode: "/auth/send-code",
  refreshToken: "/auth/token/refresh",
  userAgreementContent: "/common/contents/user-agreement",
  loginCorporate: "/corporate",
  kycVerify: "/get-kyc-applicant-id/",
  startKyc: "/mi/startKyc",
  generateAddress: "/mi/generateAddress",
  addKycDetails: "/data",
  user: "/user/me",
  tryBalance: "/user/balances/try",
  currencyBalance: "/user/balances/",
  updateAddress: "/common/updateAddress",
  getBankId: "/common/bank-id",
  transactions: "/user/histories/transactions",
  actions: "/user/histories/actions",
  notifications: "/user/settings/notifications",
  updatePassword: "/user/update/password",
  updateLanguage: "/user/update/language",
  userNotifications: "user/notifications",
  readNotifications: "user/notifications/read",
  emailOtp: "user/security/2fa/email",
  smsOtp: "user/security/2fa/sms",
  authenticatorOtp: "user/security/2fa/authenticator",
  countryCodes: "user/verification/getCountryCodesForData",
  formToCountry: "user/verification/getFieldsAndConsents?CountryCode=",
  upgradeTwoNdLevel: "user/verification/verifyData?CountryCode=",
  documentsToCountry: "user/verification/getCountryCodesForDocument",
  documentTypes: "user/verification/getDocumentTypes?CountryCode=",
  verifyDocument: "user/verification/verifyDocument",
  updateExchangeCurrency: "currency-exchange/update",
  history: "/finance/deposit/histories/try",
  createNonexistentWallet: "finance/deposit/assign/",
  createNonexistentWalletMulti: "finance/deposit/assign/multi",

  calculate: "/finance/quicksale/btc/calculate",

  buyAndSellNowPrices: "/finance/quicksale/",
  buy: "/finance/quicksale/buy",
  sell: "/finance/quicksale/sell",
  quicksaleBuyContent: "/common/contents/quicksale",
  quicksaleSellContent: "/common/contents/quicksale-sell",

  withdraw: "/finance/withdraw/wallets",
  providers: "/finance/withdraw/providers/",
  withdrawCreate: "finance/withdraw/create",
  createWallet: "finance/withdraw/wallets/create",
  bankAccountList: "finance/withdraw/wallets/bank_transfer/",
  withdrawHistory: "/finance/withdraw/histories/",
  withdrawContent: "/common/contents/withdraw",

  depositProviders: "/finance/deposit/providers",
  depositCreate: "finance/deposit/create",
  fiatDepositCreate: "finance/deposit/create-fait-deposit",
  fiatDepositlist: "finance/deposit/fait-deposit",
  cryptoDepositlist: "finance/deposit/crypto-deposit",
  depositApprove: "finance/deposit/approved/",
  depositHistory: "finance/deposit/histories/",
  depositContent: "/common/contents/deposit",
  depositCancel: "finance/deposit/cancel/approve/",
  depositCreateInfos: "finance/deposit/",
  depositAddress: "/address",

  infosetApi: "/common/infoset",
  spotTrade: {
    allPairs: "spottrade/allpair",
    createOrder: "spottrade/create",
    cancelOrder: "spottrade/cancel",
    orderHistory: "spottrade/orderhistory",
    symbolPlate: (symbol) => `spottrade/plate?symbol=${symbol}`,
  },
  google_authenticator: "user/security/2fa/authenticator",
};

export default api;
