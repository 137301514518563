<template>
  <div class="table-responsive">
    <table class="table table-hover table-xl">
      <thead>
        <tr>
          <th class="border-top-0">{{ showWallets ? $t('withdrawal-addCryptoWalletLabel') : $t('withdrawal-accountName') }}</th>
          <th class="border-top-0" v-if="!showWallets">{{ $t('withdrawal-bank') }}</th>
          <th class="border-top-0">{{ showWallets ? $t('withdrawal-wallet') : $t('withdrawal-account') }}</th>
          <th class="border-top-0"></th>
        </tr>
      </thead>
      <tbody>
        <template v-if="isLoading">
          <tr>
            <td colspan="6" class="text-center loading-content">
              <div class="-loading -dark"></div>
            </td>
          </tr>
        </template>
        <template v-else-if="wallet.length === 0">
          <tr>
            <td colspan="6" class="text-center">{{ $t('withdrawal-datatable-noData') }}</td>
          </tr>
        </template>
        <template v-else>
          <tr v-for="(item, index) in wallet" :value="item.code" :key="'bank-' + index">
            <td class="text-truncate">{{ item.label }}</td>
            <td v-if="!showWallets">{{ item.bank }}</td>
            <td class="text-truncate">{{ item.address }}</td>
            <td>
              <button type="button" class="btn btn-secondary btn-sm" @click="deleteBank(item.code)">
                {{ $t('bankSettings-delete') }}
              </button>
            </td>
          </tr>
        </template>
      </tbody>
    </table>
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex';

export default {
  name: 'BankList',
  props: {
    showWallets: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState({
      wallet: (state) => state.withdrawal.wallet,
      isLoading: (state) => state.withdrawal.isLoading
    })
  },
  methods: {
    ...mapActions('withdrawal', ['DELETE_BANK']),
    deleteBank(code) {
      this.DELETE_BANK(code);
    }
  }
};
</script>

<style scoped></style>
