<template>
  <div>
    <ValidationObserver ref="form" v-slot="{ handleSubmit }">
      <form method="post" @submit.prevent="handleSubmit(buyBtn)">
        <div class="form-group">
          <label class="mr-sm-2">{{ $t('instanttrade-label-cryptotype') }}</label>
          <div class="row">
            <validation-provider rules="required" v-slot="{ classes, errors }" class="col-12">
              <multiselect v-model="quicksale.crypto" @input="getCoinSelection" deselect-label="" select-label=""
                selected-label="" track-by="name" label="name" :custom-label="vueMultiselectCustomLabel"
                :show-labels="false" placeholder="Select one" :options="getFilteredCrypto" :allow-empty="false"
                :class="classes" open-direction="bottom">

                <template slot="option" slot-scope="{ option }">{{ `${option.name} (${option.code})` }}</template>
                <template slot="noOptions">{{ $t('noOptions') }}</template>
                <template slot="noResult">{{ $t('noOptions') }}</template>
              </multiselect>
              <span class="error__message">{{ errors[0] }}</span>
            </validation-provider>
          </div>
        </div>
        <div class="form-group">
          <label class="mr-sm-2">{{ $t('instanttrade-label-currenciestype') }}</label>
          <div class="row">
            <validation-provider rules="required" v-slot="{ classes, errors }" class="col-12">
              <multiselect v-model="quicksale.currency" @input="getCoinSelection" deselect-label="" select-label=""
                selected-label="" track-by="name" label="name" :custom-label="vueMultiselectCustomLabel"
                :show-labels="false" placeholder="Select one" :options="getFilteredCurrency" :allow-empty="false"
                :class="classes" open-direction="bottom">
                <!-- <template slot="singleLabel" slot-scope="{ option }">{{ `${option.name} (${option.code})` }}</template> -->
                <template slot="option" slot-scope="{ option }">{{ `${option.name} (${option.code})` }}</template>
                <template slot="noOptions">{{ $t('noOptions') }}</template>
                <template slot="noResult">{{ $t('noOptions') }}</template>
              </multiselect>
              <span class="error__message">{{ errors[0] }}</span>
            </validation-provider>
          </div>
        </div>
        <div class="form-group">
          <label class="mr-sm-2">{{ $t('instanttrade-label-quantity') }}</label>
          <div class="row">
            <validation-provider rules="required" :name="$t('instanttrade-label-quantity')" v-slot="{ classes, errors }"
              class="col-12">
              <currency-input id="usd_amount" class="form-control" :class="classes" :precision="8"
                v-model="quicksale.amount" @keyup="getCalculate('amount')" @blur="clearCalculateForm" />
              <span class="error__message">{{ errors[0] }}</span>
            </validation-provider>
          </div>
        </div>
        <div class="form-group">
          <label class="mr-sm-2">{{ $t('instanttrade-enterTotal') }}</label>
          <div class="row">
            <validation-provider rules="required" :name="$t('instanttrade-label-amount')" v-slot="{ classes, errors }"
              class="col-12">
              <currency-input id="currency_amount" class="form-control" :class="classes" v-model="quicksale.total"
                :precision="quicksaleCurrenyIsCrypto" @keyup="getCalculate('total')" @blur="clearCalculateForm" />
              <span class="error__message">{{ errors[0] }}</span>
              <div class="alert alert-warning" role="alert">
                {{ $t('instanttrade-amount-warning', {amount: `€${minTradeTotal}`}) }}
              </div>
              <PercentButtons @onSelectedPercent="onSelectedPercent" v-if="quicksale.crypto" ref="percentButtons"
                class="my-sm-1" />
            </validation-provider>
          </div>
        </div>
        <div class="form-group">
          <div class="d-flex justify-content-between mt-1">
            <p class="mb-0">{{ $t('instanttrade-availablelimit') }}</p>
            <h6 class="mb-0" v-if="quicksale.currency">{{ getSelectedCurrency(quicksale.currency.code)[0] }}</h6>
          </div>
          <div class="d-flex justify-content-between mt-1">
            <p class="mb-0">{{ $t('instanttrade-buyPrice') }}</p>
            <h6 class="mb-0">{{ buyNow.priceSell }}</h6>
          </div>
          <div class="d-flex justify-content-between mt-1">
            <p class="mb-0">{{ $t('instanttrade-buycommission') }}</p>
            <h6 class="mb-0">{{ quicksale.fee }}</h6>
          </div>
        </div>
        <div class="col-12 text-center mt-2 p-0">
          <button type="submit" class="btn-gradient-success btn-block">{{ $t('instanttrade-btn-buynow') }}</button>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import Vue from 'vue';
import { mapState, mapActions, mapGetters } from 'vuex';
import Multiselect from 'vue-multiselect';
import PercentButtons from "@/components/global/PercentButtons.vue";
import utils from "@/config/utils";

export default {
  name: 'BuyNow',
  props: {
    minTradeTotal: {
      type: Number,
      default: 5
    }
  },
  components: {
    Multiselect,
    PercentButtons
  },
  data() {
    return {
      quantityPlaceHolder: this.$t('instanttrade-label-quantity')
    };
  },
  methods: {
    ...mapActions('global', ['GET_CURRENCY']),
    ...mapActions('quicksale', [
      'POST_CALCULATE',
      'POST_BUY',
      'GET_TRY_BALANCE',
      'GET_BUY_NOW_PRICES',
      'GET_CURRENCY_BALANCE',
      'GET_SELL_NOW_PRICES',
      'GET_STATIC_CONTENT_BUY',
      'GET_HISTORY'
    ]),
    buyBtn() {
      if (parseFloat(this.quicksale.total) < this.minTradeTotal) {
        Vue.$toast.error(this.$t('instanttrade-buy-invalidAmount', { amount: `${this.minTradeTotal} ${this.quicksale.currency.code}` }));
        return;
      }
      this.$store
        .dispatch('quicksale/POST_BUY')
        .then(() => {
          this.$refs.form.reset();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getCalculate(calcType) {
      this.$refs.percentButtons.selectPercent("");
      if (this.quicksale.amount === null && this.quicksale.total === null) {
        return;
      } else if (calcType === 'total' && this.quicksale.total === null) {
        this.quicksale.amount = null;
        return;
      } else if (calcType === 'amount' && this.quicksale.amount === null) {
        this.quicksale.total = null;
        return;
      }
      this.POST_CALCULATE({ buy: 10, calcType });
    },
    getCoinSelection() {
      this.$store.commit('quicksale/CLEAR_FORM');
      if (this.quicksale.currency && this.quicksale.crypto) {
        this.GET_BUY_NOW_PRICES();
        this.GET_CURRENCY_BALANCE();
        this.GET_SELL_NOW_PRICES();
        this.GET_HISTORY();
        this.setQuantityPlaceHolder();
      }
      this.$refs.form.reset();
    },
    clearCalculateForm() {
      this.$refs.form.reset();
    },
    setQuantityPlaceHolder() {
      if (this.quicksale?.crypto) {
        const { min_pay_decimal, max_pay_decimal } = this.quicksale.crypto;
        if (min_pay_decimal && max_pay_decimal) {
          this.quantityPlaceHolder = `${parseFloat(min_pay_decimal).toFixed(8)} - ${parseFloat(max_pay_decimal).toFixed(8)}`;
        }
      }
    },
    onSelectedPercent(percent) {
      if (!percent) {
        return;
      }
      const cleanBalance = this.quicksale?.crypto ? this.getSelectedCurrency(this.quicksale.currency.code)[0].replace(/[^\d.]/g, '') : 0;
      if (parseFloat(cleanBalance) > 0) {
        let p = parseInt(percent);
        this.$store.commit('quicksale/SET_QUICKSALE_TOTAL', (parseFloat(cleanBalance) * (p / 100)).toFixed(2));
        this.POST_CALCULATE({ buy: 10, calcType: 'total' });
      }
    },
    vueMultiselectCustomLabel({ name, code }) {
      return `${name} (${code})`
    }
  },
  computed: {
    ...mapState({
      quicksale: (state) => state.quicksale.quicksale,
      currency: (state) => state.global.currency,
      currency_eur: (state) => state.global.currency_eur,
      crypto: (state) => state.global.crypto,
      buyNow: (state) => state.quicksale.buyNow
    }),
    ...mapGetters('global', ['getCrypto', 'getCurrency', 'getCurrencyeur']),
    ...mapGetters('wallet', ['getSelectedCurrency']),
    ...mapActions('customer', ['UPDATE_EXCHANGE_CURRENCY']),
    getFilteredCrypto: function () {
      const vm = this;
      return this.getCrypto.filter(function (item) {
        return item.id != vm?.quicksale?.currency?.id;
      }).sort((a, b) => utils.sortArrByString(a.code, b.code));
    },
    getFilteredCurrency: function () {
      const vm = this;
      return this.getCurrencyeur.filter(function (item) {
        return item.id != vm?.quicksale?.crypto?.id && item.quicksale;
      }).sort((a, b) => utils.sortArrByString(a.code, b.code));
    },

    quicksaleCurrenyIsCrypto() {
      return this.quicksale?.currency?.crypto ? 8 : 2;
    },
  },
  watch: {
    getCurrency: function () {
      if (this.quicksale.currency === null) {
        // this.quicksale.currency = val[0];
        this.getCoinSelection();
      }
    },
    getCurrencyeur: function () {
      if (this.quicksale.currency === null) {
        this.getCoinSelection();
      }
    },
    getCrypto: function (val) {
      if (this.quicksale.crypto === null) {
        this.quicksale.crypto = val[0];
        this.getCoinSelection();
      }
    }
  },
  created() {
    this.GET_CURRENCY();
    //this.GET_TRY_BALANCE();
    //this.GET_BUY_NOW_PRICES();
    this.GET_STATIC_CONTENT_BUY();
    this.setQuantityPlaceHolder();
    this.UPDATE_EXCHANGE_CURRENCY();
  }
};
</script>

<style scoped></style>
