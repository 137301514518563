<template>
  <div>
    <div class="card-body" v-if="!$store.state.account.isHidden">
      <div class="table-responsive">
        <table class="table table-hover table-xl" v-if="bankAccountList.length">
          <thead>
            <tr>
              <th class="border-top-0">{{ $t('bankSettings-label') }}</th>
              <th class="border-top-0">{{ $t('bankSettings-bank') }}</th>
              <th class="border-top-0">{{ $t('bankSettings-iban') }}</th>
              <th class="border-top-0">{{ $t('status') }}</th>
              <th class="border-top-0">{{ $t('loginSettings-action') }}</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in bankAccountList" :key="'account-list-' + index">
              <td class="text-truncate">{{ item.label }}</td>
              <td class="text-truncate">{{ item.bank }}</td>
              <td class="text-truncate">{{ item.address }}</td>

              <td v-if="item.status === 50" class="text-truncate">Pending</td>
              <td v-else-if="item.status === 10" class="text-truncate">Active</td>
              <td v-else-if="item.status === 20" class="text-truncate">Suspended</td>
              <td v-else-if="item.status === 30" class="text-truncate">Passive</td>
              <td v-else-if="item.status === 40" class="text-truncate">Blocked</td>
              <td v-else class="text-truncate">--------</td>
              <td class="text-truncate">
                <button type="button" class="btn btn-secondary btn-sm" @click="deleteBank(item.code)">
                  {{ $t('bankSettings-deleteConfirm') }}
                </button>
              </td>
            </tr>
          </tbody>
        </table>
        <div v-else class="alert alert-primary" role="alert">
          {{ $t('bankSettings-noRegisteredBank') }}
        </div>
      </div>
      <div class="col-12 text-right">
        <!-- <button
          type="submit"
          class="btn-gradient-primary my-1"
          @click="$store.state.account.isHidden = !$store.state.account.isHidden"
        >
          {{ $t('bankSettings-addNewBank') }}
        </button> -->
        <button type="submit" class="btn-gradient-primary my-1" @click="$store.state.account.isHidden = !$store.state.account.isHidden">
          {{ $t('bankSettings-addNewBank') }}
        </button>
      </div>
    </div>
    <div class="card-body" v-if="$store.state.account.isHidden">
      <ValidationObserver ref="form" v-slot="{ handleSubmit }">
        <form method="post" @submit.prevent="handleSubmit(saveNewBank)">
          <div class="col-12">
            <validation-provider rules="required" :name="$t('bankSettings-label')" v-slot="{ classes, errors }">
              <fieldset class="form-group">
                <label class="mr-sm-2" for="account_name">{{ $t('bankSettings-label') }}</label>
                <input type="text" class="form-control" id="account_name" v-model="bank_settings.account_name" :placeholder="$t('bankSettings-enterAccountName')" :class="classes" />
                <span class="error__message">{{ errors[0] }}</span>
              </fieldset>
            </validation-provider>

            <validation-provider :rules="required" :name="$t('bankSettings-iban')" v-slot="{ classes, errors }">
              <fieldset class="form-group position-relative">
                <label class="mr-sm-2">{{ $t('bankSettings-iban') }}</label>
                <input v-model="bank_settings.iban" class="form-control iban-input" :placeholder="$t('bankSettings-enterAccountNumber')" :class="classes" v-uppercase />
                <span class="error__message">{{ errors[0] }}</span>
              </fieldset>
            </validation-provider>

            <validation-provider rules="required" v-slot="{ classes, errors }" :name="$t('withdrawal-bank')">
              <fieldset class="form-group">
                <label class="mr-sm-2">{{ $t('withdrawal-label-currenciestype') }}</label>
                <multiselect v-model="bank_settings.currency" deselect-label="" select-label="" selected-label="" id="currency" track-by="name" label="name" :placeholder="$t('bankSettings-chooseCurrency')" :options="getFilteredCurrency" :searchable="true" :allow-empty="false" :class="classes" open-direction="bottom">
                  <template slot="singleLabel" slot-scope="{ option }">{{ `${option.name}` }}</template>
                  <template slot="option" slot-scope="{ option }">{{ `${option.name}` }}</template>
                  <template slot="noOptions">{{ $t('noOptions') }}</template>
                </multiselect>

                <span class="error__message">{{ errors[0] }}</span>
              </fieldset>
            </validation-provider>
            <validation-provider rules="required" :name="$t('bankSettings-bank')" v-slot="{ classes, errors }">
              <fieldset class="form-group">
                <label class="mr-sm-2">{{ $t('bankSettings-bank') }}</label>
                <!-- <div>
                  <label class="l2" v-for="(option, index) in optionSEPA" :key="index">
      <input disabled type="checkbox" :value="option.value" v-model="selectedCheckboxes" >
      {{ option.label }} 
    </label> -->
                <!-- <label>
      <input type="checkbox" :value="getGateways[1].id" v-model="isChecked" >
      {{getGateways[1].gateway_name}} 
    </label> -->
                <!-- <Pre v-if="result">{{ option.name }} </Pre> -->
                <!-- </div> -->
                <!-- <div>
  <label v-for="(option,index) in bankServices" :key="index">
    <input type="checkbox" :value="option.id" v-model="selectedCheckboxes" >
    {{ option.name }} 
  </label>
</div> -->
                <multiselect v-model="bank_settings.bank" deselect-label="" select-label="" selected-label="" id="id" track-by="name" label="name" :placeholder="$t('bankSettings-chooseBank')" :options="bankServices" :searchable="true" :allow-empty="false" :class="classes" open-direction="bottom">
                  <template slot="singleLabel" slot-scope="{ option }">{{ `${option.name ? option.name : option.gateway_name}` }}</template>
                  <template slot="option" slot-scope="{ option }">{{ `${option.name ? option.name : option.gateway_name}` }}</template>
                  <template slot="noOptions">{{ $t('noOptions') }}</template>
                </multiselect>

                <span class="error__message">{{ errors[0] }}</span>
              </fieldset>
            </validation-provider>

            <validation-provider rules="required" :name="$t('bankSettings-issuers')" v-if="bank_settings.bank.name == 'iDEAL'" v-slot="{ classes, errors }">
              <fieldset class="form-group">
                <label class="mr-sm-2">{{ $t('bankSettings-issuers') }}</label>
                <multiselect v-model="bank_settings.issuers" deselect-label="" select-label="" selected-label="" id="id" track-by="name" label="name" :placeholder="$t('bankSettings-chooseBank')" :options="issuersData" :searchable="true" :allow-empty="false" :class="classes" open-direction="bottom">
                  <template slot="singleLabel" slot-scope="{ option }">{{ `${option.name}` }}</template>
                  <template slot="option" slot-scope="{ option }">{{ `${option.name}` }}</template>
                  <template slot="noOptions">{{ $t('noOptions') }}</template>
                </multiselect>

                <span class="error__message">{{ errors[0] }}</span>
              </fieldset>
            </validation-provider>

            <validation-provider
              :rules="{
                regex: /^([\-]?[0-9]{2})(?=(?:[ \-]?[A-Z0-9]){9,30}$)((?:[ \-]?[A-Z0-9]{3,5}){2,7})([ \-]?[A-Z0-9]{1,3})?$/gm
              }"
              :name="$t('bankSettings-iban')"
              v-slot="{ classes, errors }"
            >
              <fieldset class="form-group position-relative">
                <label class="mr-sm-2">{{ $t('bank-Proof') }}</label>
                <div class="input-group" :class="classes">
                  <input type="file" name="address" @change="handleFileUpload" class="form-control" :class="classes" :placeholder="$t('bank-Proof')" />
                </div>
                <span class="error__message">{{ errors[0] }}</span>
                <div class="d-flex w-100 mt-1">
                  <div class="alert alert-warning" role="alert">
                    {{ $t('proof_of_address_input_warning') }}
                  </div>
                </div>
              </fieldset>
            </validation-provider>

            <div class="row justify-content-center">
              <button type="button" @click="onCancelPress($store)" class="btn btn-outline-primary round btn-block col-5 col-md-3">
                {{ $t('bankSettings-cancel') }}
              </button>
              <button type="submit" class="btn btn-gradient-primary round btn-block col-6 col-md-3 offset-1">
                {{ $t('bankSettings-btnSave') }}
              </button>
            </div>
          </div>
        </form>
      </ValidationObserver>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import Multiselect from 'vue-multiselect';
import utils from '@/config/utils';

import AWS from 'aws-sdk';
export default {
  name: 'Bank',

  components: {
    Multiselect
  },
  computed: {
    ...mapState({
      banks: (state) => state.global.banks,
      bank_settings: (state) => state.account.bank_settings,
      bankAccountList: (state) => state.account.bankAccountList,
      user: (state) => state.customer.user,
      gateways: (state) => state.account.gateways,
      bankServices: (state) => state.account.bankServices,
      issuersData: (state) => state.account.issuersData,
      progressStatusColors: (state) => state.global.progressStatusColors,
      // selectBank:false,
      // bankServices:(state) => state.account.bankServices,
      //   showRadioButtons() {
      //   return this.selectedCheckboxes.length > 0;
      // },
      //   options: (state)=>[
      //   // { label:state.account.gateways[0].gateway_name, value: state.account.gateways[0].id },
      //   { label: state.account.gateways[1].gateway_name, value: state.account.gateways[1].id },
      // ],
      optionSEPA: (state) => [
        { label: state.account.gateways[0].gateway_name, value: state.account.gateways[0].id }
        // { label: state.account.gateways[1].gateway_name, value: state.account.gateways[1].id },
      ],
      selectedCheckboxes: (state) => [state.account.gateways[0].id],
      selectedCheckOnline: (state) => [state.account.gateways[1].id]
      // bankServices: (state)=>[state.account.gateways[1].services],
    }),

    ...mapGetters('global', ['getCurrency']),
    ...mapGetters('account', ['getGateways']),
    getFilteredCurrency: function () {
      return this.getCurrency.filter(function (item) {
        return item.crypto === false;
      });
    },
    options: function () {
      return [
        { label: this.getGateways[1].gateway_name, value: this.getGateways[1].id }
        //  { label: this.getGateways[0].gateway_name, value: this.getGateways[0].id },
      ];
    }
  },
  data() {
    // console.log(this.gateways[0].gateway_name,"dfghdyhdydrydrt");
    return {
      ibanCountryCode: '',
      // selectedCheckOnline:null,
      //isChecked: false

      //   options: [
      //   { label:'this.gateways[0].gateway_name', value: 'option1' },
      //   { label: 'this.gateways[1].gateway_name', value: 'option2' },
      // ],
      // selectedCheckboxes: ['option1']
      isChecked: false,
      result: ''
    };
  },
  methods: {
    ...mapActions('global', ['GET_BANKS', 'GET_CURRENCY']),
    ...mapActions('account', ['ADD_BANK_ACCOUNT', 'GET_BANK_ACCOUNT_LIST', 'DELETE_BANK', 'GET_GATEWAY_LIST']),
    saveNewBank() {
      const file = this.selectedFile;
      AWS.config.update({
        region: 'eu-central-1'
      });
      var s3 = new AWS.S3({
        apiVersion: '2006-03-01',
        accessKeyId: 'AKIAQ3EGRUUZZGEN2A6U',
        secretAccessKey: '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'
      });

      const params = {
        ACL: 'public-read',
        Bucket: 'miraclecash',
        Key: this.user.id + '/bank_proof/' + file.name,
        Body: file
      };
      s3.upload(params, (err, data) => {
        if (err) {
          console.error('Error:', err);
        } else {
          console.log('Successfully uploaded:', data.Location);
          this.bank_settings.imageUrl = data.Location;
          this.ADD_BANK_ACCOUNT();
        }
      });
    },
    getGateway() {
      this.GET_GATEWAY_LIST({ id: this.user.id });
    },
    onCancelPress($store) {
      $store.state.account.isHidden = !$store.state.account.isHidden;
    },
    handleFileUpload(event) {
      this.selectedFile = event.target.files[0];
    },
    deleteBank(code) {
      this.$swal({
        icon: 'warning',
        title: this.$t('bankSettings-deleteTitle'),
        text: this.$t('bankSettings-deleteAccount'),
        confirmButtonText: this.$t('bankSettings-deleteConfirm'),
        showCancelButton: true,
        reverseButtons: true,
        cancelButtonText: this.$t('bankSettings-cancel')
      }).then((result) => {
        if (result.isConfirmed) {
          this.DELETE_BANK(code);
        }
      });
    },
    getProgressValue: utils.getProgressValue,
    statusColor: utils.getProgressColor,
    changeInput(option) {
      console.log(option, 'tererte');
    }
  },
  handleCheckedChange() {
    // Get the current value of the checkbox
    const isChecked = this.isChecked;

    // Do something based on the value of the checkbox
    if (isChecked) {
      console.log('Checkbox is checked!');
      this.result = 'Checkbox is checked!';
      // ... do something else when the checkbox is checked ...
    } else {
      this.result = 'Checkbox is unchecked!';
      // ... do something else when the checkbox is unchecked ...
    }
  },
  directives: {
    uppercase: {
      update(el) {
        el.value = el.value.toUpperCase();
      }
    }
  },
  watch: {
    'bank_settings.bank': {
      handler(bank) {
        if (bank) {
          let addressPrefix = bank.country.code;
          if (bank.type === 'UBAN') addressPrefix = 'ct';
          this.ibanCountryCode = addressPrefix;
        }
      }
    }

    // isChecked(services) {
    //   if (services) {
    //     // Checkbox is selected, perform your action here
    //     console.log('Checkbox selected!');
    //   } else {
    //     // Checkbox is deselected
    //     console.log('Checkbox deselected!');
    //   }
    // }
  },

  created() {
    this.$store.dispatch('customer/GET_USER').then(() => {
      this.GET_GATEWAY_LIST({ id: this.user.id });
    }).catch(() => {})
    this.GET_BANKS();
    this.GET_CURRENCY();
    this.GET_BANK_ACCOUNT_LIST();
  }
};
</script>

<style scoped>
label.l2 {
  margin-right: 15px;
}
</style>
