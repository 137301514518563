<template>
  <div class="card-body">
    <div class="text-center mb-4">
      <p class="text-dark font-weight-bold">{{ $t('upgradeYourAccount') }}</p>
      <p class="text-dark font-weight-bold">Enable KYC access on your account to Enjoy more benefits.</p>
    </div>
    <ValidationObserver ref="form" v-slot="{ handleSubmit }">
      <form method="post" @submit.prevent="handleSubmit(upgradeLevel)">
        <div class="col-12">
          <div v-for="(commerceNum, index) in formData.commerceNumber" :key="`commerce-number-${index}`" class="mb-2">
            <validation-provider rules="required" :name="$t('manuel_kyc.commerceNumber')" v-slot="{ classes, errors }" class="d-flex flex-column mb-1">
              <div class="d-flex flex-row justify-content-between" v-if="index === 0">
                <label for="commerceNumber" class="col-form-label col-form-label-lg">{{ $t('manuel_kyc.commerceNumber') }}</label>
                <div>
                  <button class="btn btn-outline-success btn-sm" type="button" @click="addCommerceNumber">
                    <i class="la la-plus"></i>
                  </button>
                </div>
              </div>
              <div class="input-group">
                <input type="text" v-model="formData.commerceNumber[index]" class="form-control form-control-lg" :class="classes" />
                <div class="input-group-append" v-if="index !== 0">
                  <button class="btn btn-outline-danger" type="button" @click="removeCommerceNumber(index)">
                    <i class="la la-minus"></i>
                  </button>
                </div>
              </div>
              <span v-if="errors.length" class="text-danger font-small-2">{{ errors[0] }}</span>
            </validation-provider>
          </div>
          <div class="mb-2">
            <validation-provider rules="required" :name="$t('manuel_kyc.taxNumber')" v-slot="{ classes, errors }" class="d-flex flex-column">
              <label for="taxNumber" class="col-form-label col-form-label-lg">{{ $t('manuel_kyc.taxNumber') }}</label>
              <input id="taxNumber" type="text" v-model="formData.taxNumber" class="form-control form-control-lg" :class="classes" />
              <span class="error__message">{{ errors[0] }}</span>
            </validation-provider>
          </div>

          <div class="mb-2">
            <label class="col-form-label col-form-label-lg">{{ $t('manuel_kyc.uboFile') }}</label>
            <input id="uboFile" @change="($event) => handleFileChange($event, 'uboFile')" type="file" />
            <label for="uboFile" class="custom-file-btn">
              <span>{{ $t('browseFile') }}</span>
            </label>
            <FileCardRow v-if="formData.uboFile && formData.uboFile?.length > 0" :files="formData.uboFile" @onRemoveFile="onRemoveFile('uboFile', $event)" class="my-1" />
            <FileInputErrorsCard v-if="fileErrors.uboFile" :errors="fileErrors.uboFile" />
            
          </div>

          <div class="mb-2">
            <label class="col-form-label col-form-label-lg">{{ $t('manuel_kyc.idCardsIncludingUbosFiles') }}</label>
            <input id="idCardsIncludingUbosFiles" @change="($event) => handleFileChange($event, 'idCardsIncludingUbosFiles')" type="file" multiple />
            <label for="idCardsIncludingUbosFiles" class="custom-file-btn">
              <span>{{ $t('browseFiles') }}</span>
            </label>
            <FileCardRow v-if="formData.idCardsIncludingUbosFiles && formData.idCardsIncludingUbosFiles?.length > 0" :files="formData.idCardsIncludingUbosFiles" @onRemoveFile="onRemoveFile('idCardsIncludingUbosFiles', $event)" class="my-1" />
            <FileInputErrorsCard v-if="fileErrors.idCardsIncludingUbosFiles" :errors="fileErrors.idCardsIncludingUbosFiles" />
          </div>

          <div class="mb-2">
            <label class="col-form-label col-form-label-lg">{{ $t('manuel_kyc.companyActivitiesFile') }}</label>
            <input id="companyActivitiesFile" @change="($event) => handleFileChange($event, 'companyActivitiesFile')" type="file" />
            <label for="companyActivitiesFile" class="custom-file-btn">
              <span>{{ $t('browseFile') }}</span>
            </label>
            <FileCardRow v-if="formData.companyActivitiesFile && formData.companyActivitiesFile?.length > 0" :files="formData.companyActivitiesFile" @onRemoveFile="onRemoveFile('companyActivitiesFile', $event)" class="my-1" />
            <FileInputErrorsCard v-if="fileErrors.companyActivitiesFile" :errors="fileErrors.companyActivitiesFile" />
          </div>

          <div class="mb-2">
            <div class="d-flex align-items-center">
              <label class="col-form-label col-form-label-lg">{{ $t('manuel_kyc.sourceOfFundFiles') }}</label>
              <div class="checkbox ml-2">
                <input type="checkbox" class="checkbox__input" id='sourceOfFundSwitch' v-model="formData.sourceOfFund" :checked="formData.sourceOfFund" @change="onChangeSourceOfFund" />
                <label class='checkbox__label' for='sourceOfFundSwitch'></label>
              </div>
            </div>
            <template v-if="formData.sourceOfFund">
              <input id="sourceOfFundFiles" @change="($event) => handleFileChange($event, 'sourceOfFundFiles')" type="file" multiple />
              <label for="sourceOfFundFiles" class="custom-file-btn">
                <span>{{ $t('browseFiles') }}</span>
              </label>
              <FileCardRow v-if="formData.sourceOfFundFiles && formData.sourceOfFundFiles?.length > 0" :files="formData.sourceOfFundFiles" @onRemoveFile="onRemoveFile('sourceOfFundFiles', $event)" class="my-1" />
              <FileInputErrorsCard v-if="fileErrors.sourceOfFundFiles" :errors="fileErrors.sourceOfFundFiles" />
            </template>
          </div>

          <div class="mb-2">
            <label class="col-form-label col-form-label-lg">{{ $t('manuel_kyc.orgChartFiles') }}</label>
            <input id="orgChartFiles" @change="($event) => handleFileChange($event, 'orgChartFiles')" type="file" multiple />
            <label for="orgChartFiles" class="custom-file-btn">
              <span>{{ $t('browseFiles') }}</span>
            </label>
            <FileCardRow v-if="formData.orgChartFiles && formData.orgChartFiles?.length > 0" :files="formData.orgChartFiles" @onRemoveFile="onRemoveFile('orgChartFiles', $event)" class="my-1" />
            <FileInputErrorsCard v-if="fileErrors.orgChartFiles" :errors="fileErrors.orgChartFiles" />
          </div>
          <div class="d-flex font-weight-bold text-dark">
            <i class="la la-asterisk font-small-2 text-danger"></i>
            <span v-html="$t('manuel_kyc.fileWarning', {types: '(PDF, PNG, JPG, JPEG)', size: '50 MB'})"></span>
          </div>
          <div class="d-flex font-weight-bold text-dark" v-if="totalFileSizes">
            <i class="la la-asterisk font-small-2 text-danger"></i>
            <span>Total File Sizes: {{ getFileSize(totalFileSizes) }}</span>
          </div>
        </div>
        <div class="mt-5 col-12">
          <div class="" style="column-gap: 5px">
            <button :type="isSubmitting ? 'button' : 'submit'" class="btn-gradient-primary btn-block text-capitalize" :disabled="isSubmitting">
              {{ isSubmitting ? $t('accountSettings-btnSending') : $t('accountSettings-btnSave') }}
            </button>
            <button type="button" @click.prevent="$store.commit('account/cancelUpgrade')" class="btn btn-outline-primary btn-block" :disabled="isSubmitting">
              {{ $t('commons.cancel') }}
            </button>
          </div>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import FileCardRow from '../global/FileCardRow.vue';
import FileInputErrorsCard from '../global/FileInputErrorsCard.vue';
import utils from '../../config/utils';

export default {
  name: 'ManuelKyc',
  data() {
    return {
      isSubmitting: false,
      formData: {
        commerceNumber: [''],
        taxNumber: null,
        uboFile: null,
        idCardsIncludingUbosFiles: null,
        companyActivitiesFile: null,
        sourceOfFund: false,
        sourceOfFundFiles: null,
        orgChartFiles: null
      },
      fileErrors: {
        uboFile: null,
        idCardsIncludingUbosFiles: null,
        companyActivitiesFile: null,
        sourceOfFundFiles: null,
        orgChartFiles: null
      },
      totalFileSizes: 0
    };
  },
  components: {
    FileCardRow,
    FileInputErrorsCard
  },
  computed: {},
  methods: {
    ...mapActions('customer', ['GET_USER', 'verifyManuelKyc']),
    async upgradeLevel() {
      if (this.validateFileErrorsOnSubmit()) {
        return;
      }
      if(this.validateFileSizes()) {
        this.$toast.error('The size of all selected files should not exceed 50 MB.');
        return;
      }
      let _form = new FormData();
      Object.entries(this.formData).forEach(([key, value]) => {
        if (['idCardsIncludingUbosFiles', 'sourceOfFundFiles', 'orgChartFiles', 'commerceNumber'].includes(key)) {
            if(key === 'sourceOfFundFiles' && !this.formData.sourceOfFund) {
                _form.append(`${key}[]`, null);
            } else {
                Array.from(value).forEach((file) => {
                  _form.append(`${key}[]`, file);
                });
            }
        } else if (['uboFile', 'companyActivitiesFile'].includes(key)) {
          _form.append(`${key}`, value[0]);
        } else {
          _form.append(key, value);
        }
      });
      this.isSubmitting = true;
      try {
          await this.verifyManuelKyc(_form);
          this.isSubmitting = false;
          this.GET_USER();
          this.$store.commit('account/cancelUpgrade');
      } catch (error) {
        this.isSubmitting = false;
      }
    },
    removeCommerceNumber(idx) {
      if (this.formData.commerceNumber.length > 1) {
        this.formData.commerceNumber.splice(idx, 1);
      }
    },
    addCommerceNumber() {
      this.formData.commerceNumber.push('');
    },
    handleFileChange(event, fileKeyName) {
      this.fileErrors = {
        ...this.fileErrors,
        [fileKeyName]: null
      };
      const fromFiles = Array.from(event.target.files);
      if (fromFiles.length > 0) {
        this.formData[fileKeyName] = Array.from(event.target.files);
        this.validateFileTypes(fromFiles, fileKeyName);
        this.validateFileSizes();
      }
    },
    validateFileErrorsOnSubmit() {
      let requiredFileKeys = ['idCardsIncludingUbosFiles', 'orgChartFiles', 'uboFile', 'companyActivitiesFile'];
      if (this.formData.sourceOfFund) {
        requiredFileKeys = [
            ...requiredFileKeys,
            'sourceOfFundFiles'
        ]
      }
      for (const [key, value] of Object.entries(this.formData)) {
        if (requiredFileKeys.includes(key) && (!value || value === '')) {
            this.fileErrors = {
              ...this.fileErrors,
              [key]: [`${this.$te(`manuel_kyc.${key}`) ? this.$t(`manuel_kyc.${key}`) : key} is required.`]
            };
        }
      }
      // eslint-disable-next-line no-unused-vars
      const result = Object.entries(this.fileErrors).map(([key, val]) => val)
        .filter((val) => val && val?.length > 0 && val[0] !== '');
      return result.length > 0;
    },
    onRemoveFile(fileKeyName, idx) {
      this.fileErrors = {
        ...this.fileErrors,
        [fileKeyName]: null
      };
      this.formData[fileKeyName]?.splice(idx, 1);
      this.validateFileSizes();
    },
    validateFileTypes(fileArray, fileKeyName) {
      const allowedTypes = ['image/jpeg', 'image/png', 'application/pdf'];
      const invalidFiles = fileArray.filter((file) => !allowedTypes.includes(file.type));
      if (invalidFiles.length > 0) {
        this.fileErrors = {
          ...this.fileErrors,
          [fileKeyName]: invalidFiles.map((file) => `${file.name} has an invalid file type.`)
        };
      }
    },
    validateFileSizes() {
      const getFilesArray = (fileArr) => fileArr?.length > 0 ? fileArr : [];
      const fileArray = [
        ...getFilesArray(this.formData.uboFile),
        ...getFilesArray(this.formData.idCardsIncludingUbosFiles),
        ...getFilesArray(this.formData.companyActivitiesFile),
        ...getFilesArray(this.formData.sourceOfFundFiles),
        ...getFilesArray(this.formData.orgChartFiles)
      ];
      const maxSize = 50 * 1024 * 1024; // 50 MB
      const totalFileSizes = fileArray.reduce(
        (accumulator, currentValue) => accumulator + currentValue.size,
        0,
      );
      this.totalFileSizes = totalFileSizes;
      return totalFileSizes > maxSize;
    },
    onChangeSourceOfFund() {
        this.fileErrors = {
          ...this.fileErrors,
          sourceOfFundFiles: null
        };
        this.formData.sourceOfFundFiles = null;
    },
    getFileSize: utils.getFileSize,
  },
  created() {}
};
</script>

<style lang="scss" scoped>
.is-invalid ~ .error__message {
  display: block;
}

[hidden] {
  display: none !important;
}

[type="file"] {
  height: 0;
  overflow: hidden;
  width: 0;
}

[type="file"] + label {
  background: transparent;
  border: none;
  border-radius: 5px;
  color: #642583;
  cursor: pointer;
  display: inline-block;
	font-size: inherit;
  font-weight: 500;
  outline: none;
  padding: 0.75rem 1rem;
  position: relative;
  transition: all 0.3s;
  vertical-align: middle;
  text-align: center;
  width: 100%;
  
  &:hover {
    opacity: 0.9;
  }
    
  &.custom-file-btn {
    border-radius: 40px;
    overflow: hidden;
    
    border: 1px solid #642583;
    
    span {
      display: inline-block;
      height: 100%;
      transition: all 0.3s;
      width: 100%;
      font-weight: 600;
    }
    &::before {
      color: #fff;
      content: "\f194";
      font-family: 'LineAwesome';
      font-size: 130%;
      height: 100%;
      left: 0;
      line-height: 2.6;
      position: absolute;
      top: -180%;
      transition: all 0.3s;
      width: 100%;
    }
    &:hover {
      background-color: #642583;
      border: 1px solid #642583;
      color: #fff;
      span {
        transform: translateY(300%);
      }
        
      &::before {
        top: 0;
      }
    }
  }
}
</style>
