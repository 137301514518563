/* eslint-disable no-debugger */
import Vue from 'vue';
import API from '@/api';
import Services from '@/config/_axios';
import i18n from '../../config/_i18n';
import { getSuccessServiceMsg, getErrorServiceMsg } from '../../config/serviceMessages';

const deposit = {
  state: () => ({
    selectedCurrency: i18n.locale === 'tr' ? { code: 'EUR', name: 'EURO' } : { code: 'EUR', name: 'EURO' },
    userBalance: 0,
    amount: null,
    wallet: [],
    walletStatus: null,
    bankList: [],
    selectedBank: null,
    selectedBankCurrency: {
      code: 'EUR',
      name: 'EURO'
    },
    generateAddress: {
      address: '',
      fireBlockVaultId: ''
    },
    transfer: {},
    address: null,
    user_id: null,
    asset_id: null,
    vault_id: null,
    isCrypto: false,
    isDeposit: false,
    history: [],
    bankDetailDeposit: [],
    next: '',
    selectedCountry: null,
    isLoading: true,
    networks: [],
    selectedNetwork: null,
    buttonLoading: false,
    hideIssuersModal: false
  }),
  mutations: {
    IS_BUTTON_DISABLED(state, payload) {
      state.buttonLoading = payload;
    },
    IS_LOADING(state, payload) {
      state.isLoading = payload;
    },
    SET_HISTORY(state, payload) {
      state.next = payload.payload.links.next;
      if (!payload.isNextPage) {
        state.history = [];
      }
      for (let idx in payload.payload.data) {
        state.history.push(payload.payload.data[idx]);
      }
    },
    SET_HISTORY_BANK(state, payload) {
      state.next = payload.links.next;
      if (!payload?.isNextPage) {
        state.history = [];
      }
      for (let i = 0; i < payload.data.length; i++) {
        state.history.push(payload.data[i]);
      }
    },
    SET_GENERATE_ADDRESS(state, payload) {
      state.generateAddress.address = payload.data.data.address;
      state.generateAddress.fireBlockVaultId = payload.data.data.fireBlockVaultId;
    },
    SET_ACCOUNT_DETAILS(state, payload) {
      state.bankDetailDeposit = payload?.data?.data?.detail;
    },
    SET_DEPOSIT(state, payload) {
      if (state.selectedCurrency.crypto === true) {
        //this is empty
      } else {
        state.bankList = [];
        for (let i = 0; i < payload.data.length; i++) {
          state.bankList.push({
            bank: payload.data[i].address + '-' + payload.data[i].bank_detail[0].name,
            bank_id: payload.data[i].bank,
            bank_code: payload.data[i].bank_detail[0].code,
            wallet_code: payload.data[i].code
          });
        }
      }
    },
    SET_CURRENCY_BALANCE(state, payload) {
      state.userBalance = payload.data.available_formatted;
      state.walletStatus = payload.data.wallet;
    },
    updateSelectedCurrency(state, value) {
      state.selectedCurrency = value;
    },
    SET_ADDRESS_DATA(state, payload) {
      state.address = payload.address;
      state.asset_id = payload.assetId;
      state.vault_id = payload.fireBlockVaultId;
      state.user_id = payload.id;
      state.tag = payload.tag;
    },
    updateSelectedBank(state, value) {
      state.selectedBank = value;
      if (value == '8') {
        state.selectedBankCurrency = 'GBP';
      } else {
        state.selectedBankCurrency = 'TRY';
      }
    },
    updateAmount(state, value) {
      state.amount = value;
    },
    CLEAR_DEPOSIT(state) {
      state.amount = null;
      state.selectedBank = null;
    },
    SET_CREATE_DEPOSIT(state, payload) {
      if (!payload) {
        state.isDeposit = false;
        state.transfer = null;
        return;
      }
      state.isDeposit = true;
      state.transfer = payload;
    },
    updateSelectedCountry(state, value) {
      state.selectedCountry = value;
    },
    onChangeSelectedNetwork(state, payload) {
      state.selectedNetwork = payload;
      state.wallet = payload;
    },
    SET_WALLET(state, wallets) {
      state.wallet = [];
      state.networks = [];
      state.selectedNetwork = null;
      if (state.selectedCurrency.crypto === true && wallets?.length > 0) {
        if (state.selectedCurrency.code === 'USDT') {
          // set networks
          state.networks = wallets;
          state.selectedNetwork = wallets[0];
        }
        state.wallet = wallets[0];
      }
    },
    onChangeHideIssuersModal(state, payload) {
      state.hideIssuersModal = payload;
    }
  },
  actions: {
    GET_HISTORY({ state, commit }, params) {
      if (params.currency == 'eur') {
        var data = {
          user_id: params.user_id,
          admin_id: state.user_id
        };
        Services.post(API.fiatDepositlist + '?page=1', data)
          .then((res) => {
            commit('SET_HISTORY_BANK', res.data.data);
            commit('IS_LOADING', false);
          })
          .catch((err) => {
            if (err?.response?.data?.message) {
              Vue.$toast.error(getErrorServiceMsg(err.response));
            }
          });
      } else {
        var dataCrypto = {
          user_id: params.user_id,
          currency: params.currency,
          admin_id: state.user_id
        };
        Services.post(API.cryptoDepositlist + '?page=1', dataCrypto)
          .then((res) => {
            commit('SET_HISTORY_BANK', res.data.data);
            commit('IS_LOADING', false);
          })
          .catch((err) => {
            if (err?.response?.data?.message) {
              Vue.$toast.error(getErrorServiceMsg(err.response));
            }
          });
      }
    },
    async GENERATE_ADDRESS({ commit, dispatch }, params) {
      commit('IS_BUTTON_DISABLED', true);
      let data = {
        id: params.id,
        assetId: params.asset_id
      };
      const headers = {
        'X-BITDENX-KEY': process.env.VUE_APP_BDXAPI_KEY
      };
      await Services.post(process.env.VUE_APP_BDXAPI_URI + '/mi/generateAddress', data, { headers })
        .then((res) => {
          if (res.data.data) {
            commit('SET_ADDRESS_DATA', res.data.data);
            let payload = res.data.data;
            dispatch('UPDATE_ADDRESS', {
              address: payload.address,
              asset_id: payload.assetId,
              vault_id: payload.fireBlockVaultId,
              user_id: payload.id,
              data: res.data.data,
              tag: payload.tag
            });
          }
        })
        .catch((err) => {
          if (err?.response?.data?.message) {
            Vue.$toast.error(err.response.data.message);
          }
        });
    },
    GET_DEPOSIT_PROVIDERS({ commit }, { currency, user_id }) {
      if (currency == 'eur') {
        let data = {
          symbol: currency.toUpperCase(),
          id: user_id
        };
        Services.post(API.userdepostbanklist, data)
          .then((res) => {
            commit('SET_DEPOSIT', res.data);
            commit('IS_LOADING', false);
          })
          .catch((err) => {
            if (err?.response?.data?.message) {
              Vue.$toast.error(getErrorServiceMsg(err.response));
            }
          });
      }
    },

    async UPDATE_ADDRESS({ commit, state }) {
      let data = {
        address: state.address,
        user_id: state.user_id,
        asset_id: state.asset_id,
        vault_id: state.vault_id,
        tag: state.tag
      };
      await Services.post(API.updateAddress, data)
        .then(() => {
          commit('SET_ADDRESS_DATA', state.data);
        })
        .catch((err) => {
          if (err?.response?.data?.message) {
            Vue.$toast.error(getErrorServiceMsg(err.response));
          }
        });
    },

    GET_CURRENCY_BALANCE({ commit }, currency) {
      Services.get(API.currencyBalance + currency)
        .then((res) => {
          commit('SET_CURRENCY_BALANCE', res.data);
          commit('SET_WALLET', res.data?.data?.wallets || []);
          commit('IS_LOADING', false);
        })
        .catch((err) => {
          if (err?.response?.data?.message) {
            Vue.$toast.error(getErrorServiceMsg(err.response));
          }
        });
    },
    async GET_ACCOUNT_DETAIL_DEPOSIT({ commit }) {
      const headers = {
        'X-BITDENX-KEY': process.env.VUE_APP_BDXAPI_KEY
      };
      await Services.post(process.env.VUE_APP_BDXAPI_URI + '/mi/getBitdenexBankDetails', { headers })
        .then((res) => {
          commit('SET_ACCOUNT_DETAILS', res);
        })
        .catch((err) => {
          if (err?.response?.data?.message) {
            Vue.$toast.error(getErrorServiceMsg(err.response));
          }
        });
      Services.get(API.currencyBalance)
        .then((res) => {
          commit('SET_CURRENCY_BALANCE', res.data);
          commit('SET_WALLET', res.data?.data?.wallets || []);
          commit('IS_LOADING', false);
        })
        .catch((err) => {
          if (err?.response?.data?.message) {
            Vue.$toast.error(getErrorServiceMsg(err.response));
          }
        });
    },
    async POST_CREATE_DEPOSIT({ state, commit, dispatch }, user) {
      const payment_reference = user.referenceNumber;
      const mcmReqBody = {
        bank: state.selectedBank.bank,
        bank_id: state.selectedBank.bank_id,
        currency: state.selectedCurrency.code,
        amount: state.amount,
        user_id: user.id,
        unique_reference: payment_reference,
        code: payment_reference
      };
      let bitdxReqBody = {
        type: 1,
        currency: state.selectedCurrency.code,
        payment_mode: state.selectedBank.bank_code,
        fname: user.name,
        lname: user.surname,
        email: user.email,
        user_id: user.id,
        amount: state.amount,
        unique_reference: payment_reference,
        bank: state.selectedBank.bank
      };
      const bitdxReqHeaders = {
        'X-BITDENX-KEY': process.env.VUE_APP_BDXAPI_KEY
      };
      commit('IS_LOADING', true);
      try {
        await Services.post(API.fiatDepositCreate, mcmReqBody);
        const bitdenexResponse = await Services.post(process.env.VUE_APP_BDXAPI_URI + '/mi/depositFiat', bitdxReqBody, { headers: bitdxReqHeaders });
        commit('SET_CREATE_DEPOSIT', null);
        commit('CLEAR_DEPOSIT');
        const openUrl = bitdenexResponse.data?.data?.payment?.url;
        if(openUrl) {
          window.open(bitdenexResponse.data.data.payment.url, '_self');
        } else {
          Vue.$toast.success(i18n.t('service.operation_success'));
        }
        dispatch('GET_HISTORY', {
          currency: state.selectedCurrency.code.toLowerCase(),
          isNextPage: false,
          user_id: user.id
        });
        return bitdenexResponse;
      } catch (error) {
        Vue.$toast.error(getErrorServiceMsg(error.response));
        throw error;
      } finally {
        commit('IS_LOADING', false);
      }
    },
    /**
     *
     * @param {*} param0
     * this method is not being used any more 27.10.2022
     */
    PUT_APPROVE_DEPOSIT({ dispatch, state, commit }) {
      commit('IS_LOADING', true);
      Services.put(`${API.depositApprove}${state.transfer.code}`)
        .then((res) => {
          commit('SET_CREATE_DEPOSIT', null);
          Vue.$toast.success(getSuccessServiceMsg(res));
          commit('IS_LOADING', false);
          commit('CLEAR_DEPOSIT');
          dispatch('GET_HISTORY', {
            currency: state.selectedCurrency,
            isNextPage: false
          });
        })
        .catch((err) => {
          if (err?.response?.data?.message) {
            Vue.$toast.error(getErrorServiceMsg(err.response));
          }
        });
    },
    CANCEL_DEPOSIT({ /* state, dispatch, */ commit }) {
      commit('SET_CREATE_DEPOSIT', null);
      // dispatch('GET_HISTORY', { currency: state.selectedCurrency, isNextPage: false });
    },
    CANCEL_OPERATION({ state, dispatch, commit }, code) {
      Services.delete(`${API.depositCancel}${code}`)
        .then((res) => {
          Vue.$toast.success(getSuccessServiceMsg(res));
          dispatch('GET_HISTORY', {
            currency: state.selectedCurrency,
            isNextPage: false
          });
          //location.reload()
          commit('IS_LOADING', false);
        })
        .catch((err) => {
          if (err?.response?.data?.message) {
            Vue.$toast.error(getErrorServiceMsg(err.response));
          }
        });
    },
    CONFIRM_OPERATION({ state, dispatch, commit }, code) {
      Services.put(`${API.depositApprove}${code}`)
        .then((res) => {
          Vue.$toast.success(getSuccessServiceMsg(res));
          dispatch('GET_HISTORY', {
            currency: state.selectedCurrency,
            isNextPage: false
          });
          //location.reload()
          commit('IS_LOADING', false);
        })
        .catch((err) => {
          if (err?.response?.data?.message) {
            Vue.$toast.error(getErrorServiceMsg(err.response));
          }
        });
    },
    FETCH_TRANSFER({ commit }, { provider_id, currency }) {
      commit('IS_LOADING', true);
      Services.post(`${API.depositCreateInfos}`, { provider_id, currency })
        .then((res) => {
          commit('IS_LOADING', false);
          commit('SET_CREATE_DEPOSIT', res.data?.data || undefined);
        })
        .catch((err) => {
          commit('IS_LOADING', false);
          if (err?.response?.data?.message) {
            Vue.$toast.error(getErrorServiceMsg(err.response));
          }
        });
    }
  },
  getters: {},
  namespaced: true
};

export default deposit;
