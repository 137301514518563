<template>
  <div class="content-wrapper">
    <Breadcrumb :title="$t('breadcrumb-wallet-detail')" />
    <Marquee />
    <div class="row">
      <div class="col-xl-8 col-lg-8 col-md-12">
        <WalletList />
      </div>
      <div class="col-xl-4 col-lg-4 col-md-12">
        <div v-if="isLoading">
          <content-loader
            :width="547"
            :height="500"
            :speed="2"
            primaryColor="#ffffff"
            secondaryColor="#eee"
            class="loading-bg"
          >
            <circle cx="270" cy="200" r="160" />
            <rect x="140" y="380" rx="6" ry="6" width="260" height="20" />
            <rect x="140" y="420" rx="6" ry="6" width="260" height="50" />
          </content-loader>
        </div>
        <div class="card" :class="{ 'card-fullscreen': portfolioFullScreenMode }" v-else>
          <div class="card-header">
            <h6 class="card-title text-center">{{ $t('wallet-portfolioTitle') }}</h6>
            <div class="portfolio-fullsize-trigger">
              <div v-if="!portfolioFullScreenMode" class="icon" @click="changePortfolioFullScreenMode()">
                <i class="la la-expand"></i>
              </div>
              <div v-else class="icon" @click="changePortfolioFullScreenMode()"><i class="la la-compress"></i></div>
            </div>
          </div>
          <div class="card-content">
            <div class="card-body">
              <div class="text-center clearfix mb-2 chart-parent">
                <apexchart type="donut" :options="chartOptions" :series="series" height="100%"></apexchart>
              </div>
              <h3 class="text-center">{{ approximate_balance }}</h3>
            </div>
            <!-- <div class="col-12 text-center mb-2">
              <a href="#history" class="btn btn-outline-primary btn-block btn-detail">{{
                $t('wallet-transaction-detail')
              }}</a>
            </div> -->
          </div>
        </div>
        <div class="card pull-up-only-shadow">
          <div class="card-body">
            <div class="buy-sell-widget">
              <ul class="nav nav-tabs">
                <li class="nav-item">
                  <a
                    class="nav-link -buy"
                    :class="quicksale.type == 10 ? 'active' : ''"
                    @click="changeTab(10)"
                    data-toggle="tab"
                    href="#buy"
                  >
                    {{ $t('instanttrade-buynow') }}
                  </a>
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link -sell"
                    :class="quicksale.type == 20 ? 'active' : ''"
                    @click="changeTab(20)"
                    data-toggle="tab"
                    href="#sell"
                  >
                    {{ $t('instanttrade-sellnow') }}
                  </a>
                </li>
              </ul>
              <div class="tab-content tab-content-default">
                <div class="tab-pane fade" :class="quicksale.type === 10 ? 'active show' : ''" id="buy" role="tabpanel">
                  <BuyNow v-if="quicksale.type === 10" />
                </div>
                <div class="tab-pane fade" :class="quicksale.type === 20 ? 'active show' : ''" id="sell">
                  <SellNow v-if="quicksale.type === 20" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <History />
    <!-- <Modal
      v-if="buyNow.showSummaryModal"
      ref="modalBuyNowName"
      :title="$t('instanttrade-tradeSuccessSumBuy')"
      :datas="buyNow.summary"
    /> -->
    <!-- <Modal
      v-if="sellNow.showSummaryModal"
      ref="modalSellNowName"
      :title="$t('instanttrade-tradeSuccessSumSell')"
      :datas="sellNow.summary"
    /> -->
  </div>
</template>

<script>
import { ContentLoader } from 'vue-content-loader';
import VueApexCharts from 'vue-apexcharts';

import { mapActions, mapState } from 'vuex';
import WalletList from '@/components/wallet/WalletList.vue';
import BuyNow from '@/components/instant-trade/BuyNow.vue';
import SellNow from '@/components/instant-trade/SellNow.vue';
import History from '@/components/wallet/History';
import '@/assets/css/pages/wallet.css';
import Breadcrumb from '@/components/layout/Breadcrumb';
import Marquee from '@/components/markets/Marquee.vue';

export default {
  name: 'Wallet',
  components: {
    WalletList,
    BuyNow,
    SellNow,
    History,
    apexchart: VueApexCharts,
    Breadcrumb,
    ContentLoader,
    Marquee
  },
  computed: {
    ...mapState({
      wallet: (state) => state.wallet.wallet,
      series: (state) => state.wallet.series,
      approximate_balance: (state) => state.wallet.approximate_balance,
      chartOptions: (state) => {
        if (state.wallet.series.length > 9) {
          return {
            ...state.wallet.chartOptions,
            stroke: {
              width: 0
            }
          };
        }
        return state.wallet.chartOptions;
      },
      isLoading: (state) => state.wallet.isLoading,
      portfolioFullScreenMode: (state) => state.wallet.portfolioFullScreenMode,
      buyNow: (state) => state.quicksale.buyNow,
      sellNow: (state) => state.quicksale.sellNow,
      quicksale: (state) => state.quicksale.quicksale
    })
  },
  methods: {
    ...mapActions('quicksale', [
      'GET_CURRENCY',
      'GET_TRY_BALANCE',
      'GET_BUY_NOW_PRICES',
      'GET_CURRENCY_BALANCE',
      'GET_SELL_NOW_PRICES'
    ]),
    ...mapActions('wallet', ['CHANGE_PORTFOLIO_FULLSCREEN_MODE']),
    changeTab(type) {
      this.quicksale.type = type;
      this.quicksale.amount = null;
      this.quicksale.total = null;
    },
    changePortfolioFullScreenMode() {
      this.CHANGE_PORTFOLIO_FULLSCREEN_MODE();
    }
  },
  created() {
    if (this.portfolioFullScreenMode) this.changePortfolioFullScreenMode();
  }
};
</script>
<style lang="scss" scoped>
.portfolio-fullsize-trigger {
  position: absolute;
  right: 20px;
  top: 20px;

  .icon {
    cursor: pointer;
  }
}

.chart-parent {
  min-height: 350px;
}

.card-fullscreen {
  overflow: hidden;

  .card-header,
  .card-content {
    padding-left: 140px;
    @media only screen and (max-width: 768px) {
      padding-left: 0;
    }
  }

  .card-body {
    text-align: center;
  }

  .chart-parent {
    height: 80vh;
    width: 80%;
    display: inline-block;
  }

  .btn-detail {
    display: none;
  }

  .apexcharts-legend {
    display: flex;
  }
}
</style>
