/* eslint-disable no-debugger */
import Vue from 'vue';
import API from '@/api';
import Services from '@/config/_axios';
import {disabledCurrencyCodes} from './global';

const wallet = {
    state: () => ({
        wallet: [],
        history: [],
        isLoading: true,
        portfolioFullScreenMode: false,
        series: [],
        next: '',
        approximate_balance: 0,
        chartOptions: {
            colors: [
                '#9147bc',
                '#00E396',
                '#FEB019',
                '#FF4560',
                '#008FFB',
                '#3F51B5',
                '#546E7A',
                '#D4526E',
                '#A5978B',
                '#2B908F',
                '#F86624',
                '#662E9B',
                '#5C4742',
                '#2983FF',
                '#A300D6',
                '#55a630',
                '#003049',
                '#892b64',
                '#941b0c',
                '#220901'
            ],
            chart: {
                redrawOnParentResize: true,
                type: 'donut'
            },
            plotOptions: {
                pie: {
                    startAngle: -90,
                    endAngle: 270
                }
            },
            dataLabels: {
                enabled: false
            },
            fill: {
                type: 'gradient'
            },
            legend: {
                position: 'bottom'
            },
            labels: []
        },
        userBalances: []
    }),
    mutations: {
        SET_BALANCE(state, payload) {
            state.approximate_balance = payload.approximate_balance_formatted;
            state.wallet = [];
            state.series = [];
            state.chartOptions.labels = [];
            state.userBalances = payload?.user_balance || [];
            for (let key in payload.data) {
                let object = payload.data[key];
                /**
                 * @INFO disable USDTTRC,USDTPLYGN globally
                 */
                if(disabledCurrencyCodes.includes(object.currency_code)) {
                    continue
                }
                if(object.currency_code === 'USDT' && object?.wallets?.length > 0) {
                    const wallets = object.wallets.map(walletVal => {
                        const userBalanceItem = payload?.user_balance?.find(userBalance => userBalance?.id === walletVal?.usdt_asset_id);
                        return {...walletVal, userNetworkBalance: userBalanceItem?.available || '0'}
                    })
                    object = {...object, wallets}
                }
                state.wallet.push(object);
                const currencyTotal = parseFloat(object.default_currency_total);
                if (currencyTotal) {
                    state.series.push(currencyTotal);
                    state.chartOptions.labels.push(object.currency_code);
                }

            }
            state.chartOptions = Object.assign({}, state.chartOptions);
            state.isLoading = false;
        },
        IS_LOADING(state, payload) {
            state.isLoading = payload;
        },
        SET_PORTFOLIO_FULLSCREEN_MODE(state, payload) {
            state.portfolioFullScreenMode = payload;
        },
        SET_HISTORY(state, payload) {
            state.next = payload.payload.links.next;

            if (!payload.isNextPage) {
                state.history = [];
            }
            for (let idx in payload.payload.data) {
                state.history.push(payload.payload.data[idx]);
            }
        }
    },
    actions: {
        GET_BALANCE({commit/* , dispatch */}/* , createWalletAddresses = false */) {
            Services.get(API.currencyBalance)
                .then(res => {
                    commit('IS_LOADING', false);
                    commit('SET_BALANCE', res.data);
                    /* if(res.data?.data.length > 0 && createWalletAddresses) {
                        let crypto_balances = res.data.data.filter(balance => balance.crypto);
                        const nonWalletAddressBalances = crypto_balances.filter(val => ((val.wallet === undefined) || val.wallet === null));
                        if (nonWalletAddressBalances.length > 0) {
                            dispatch('CREATE_WALLET_MULTI', nonWalletAddressBalances.map(val => val.currency_code.toLowerCase()));
                        }
                    } */

                })
                .catch(err => {
                    if (err?.response?.data?.message) {
                        if (err?.response?.data?.message) {
                            Vue.$toast.error(err.response.data.message);
                        }
                    }
                });
        },
        CREATE_WALLET_MULTI({commit}, currencies) {
            const formData = new FormData()
            formData.append('currencies', encodeURI(currencies.join(',')));
            Services.post(`${API.createNonexistentWalletMulti}`, formData).then(async () => {
                commit('IS_LOADING', false);
                const balanceRes = await Services.get(API.currencyBalance);
                commit('SET_BALANCE', balanceRes.data);
            }).catch(err => {
                if (err?.response?.data?.message) {
                    console.log(err.response.data.message);
                }
            });

        },
        CREATE_WALLET({commit}, currency) {
            Services.get(`${API.createNonexistentWallet}${currency}`)
                .then(() => {
                    commit('IS_LOADING', false);
                })
                .catch(err => {
                    if (err?.response?.data?.message) {
                        console.log(err.response.data.message);
                    }
                });
        },
        GET_HISTORY({state, commit}, params) {
            let nextPage = `${API.transactions}?page=1&currency=${params.currency}`;
            if (params.isNextPage) {
                nextPage = `${state.next}&currency=${params.currency}`;
            }
            Services.get(nextPage)
                .then(res => {
                    let data = {
                        payload: res.data,
                        isNextPage: params.isNextPage
                    };
                    commit('SET_HISTORY', data);
                    commit('IS_LOADING', false);
                })
                .catch(err => {
                    if (err?.response?.data?.message) {
                        Vue.$toast.error(err.response.data.message);
                    }
                });
        },
        CHANGE_PORTFOLIO_FULLSCREEN_MODE({state, commit}) {
            commit('SET_PORTFOLIO_FULLSCREEN_MODE', !state.portfolioFullScreenMode);
        }
    },
    getters: {
        getSelectedCurrency: state => selectedCurrency => {
            return state.wallet
                .filter(wallet => wallet.currency_code === selectedCurrency)
                .map(wallet => wallet.available_formatted);
        }
    },
    namespaced: true
};

export default wallet;
